<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"
          ><i class="el-icon-delete"></i>
          删除
        </el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新 </el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`clau_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @getTableData="getDequsNow"
        @row-dblclick="dbClickJp"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { clauAPI } from '@api/modules/clau';
import { stffAPI } from '@api/modules/staff';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { debounce } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ClauList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'clau_dept_name', label: '文本部门', itemType: 'input', widthAuto: true },
        { prop: 'clau_name', label: '文本名称', itemType: 'input', widthAuto: true },
        { prop: 'clau_content', label: '文本内容', input: false, itemType: 'input', widthAuto: true },
        { prop: 'stff_name', label: '录入人', itemType: 'input', widthAuto: true },
        {
          prop: 'create_time',
          label: '录入时间',
          itemType: 'date',
          widthAuto: true,
          formatter: val => this.helper.toStringDate(val)
        },
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          fixed: 'right',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ]
        }
      ],
      searchForm: {
        clau_label: null,
        clau_name: null,
        status: null,
        stff_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        } //创建时间
      },
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/clau_add' || from.path === '/clau_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row);
    },
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getDequsNow();
      this.getStffUser();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.getClaus();
    }, 1000),
    // 获取公司抬头信息
    getClaus() {
      // const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let searchForm = this.$refs.multiTable.searchForm;
      get(clauAPI.getClaus, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 查询方法
    getClausNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getClaus();
    },
    // 刷新
    buttonRefresh() {
      // this.searchForm = {
      //   clau_label: null,
      //   clau_name: null,
      //   status: null,
      //   stff_id: null,
      //   timeValue: {
      //     startTime: null,
      //     endTime: null
      //   } //创建时间
      // };
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      // this.currentPage = 1;
      this.getClaus();
      // this.$refs.pubPagination.currentPage = 1;
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getClaus();
    },
    // 多选获取信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/clau_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.clau_id);
      });
      post(clauAPI.deleteClauByIds, { clau_id_list: ids })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/clau_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.clau_id
          })
        )
      });
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
